/* General app container */
.App {
  text-align: center;
}

/* Navigation bar */
.navy {
  display: flex;
  justify-content: space-around;
  background-color: #333;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

/* Button styling */
.navy button {
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
  flex: 1;
}

/* Button images */
.navy button img {
  width: 30px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
}

/* Button text */
.navy button p {
  font-size: 12px;
  color: #333; /* Adjust text color if needed */
}

/* Custom background color for buttons */
.mine {
  background-color: #ffffff;
}

/* Specific styling for 'home' icon */
.navy .home {
  width: 15px;
}

/* Top navigation bar */
.top {
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* Left and right padding */
  height: 60px; /* Adjust as needed */
  background-color: #fff; /* Background color */
  border-bottom: 1px solid #ddd; /* Optional: adds a border at the bottom */
}

/* Top navigation title */
.top h2 {
  font-size: 20px;
  margin-top: 1rem;
  margin-left: 2rem;
}

/* Logo image */
.top img {
  width: 60px;
  height: 60px;
  float: left;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

/* Contacts section */
.top .contacts {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Contacts image styling */
.contacts img {
  width: 60px;
  height: 60px;
}

/* Padding for top navigation */
nav.top {
  padding: 0 20px; /* Left and right padding */
  height: 40px;
}

/* Contacts section title */
.contacts h2 {
  margin-right: 20px; /* Spacing between text and button */
}

/* Button inside contacts section */
.contacts .btn {
  background: none;
  border: none;
  padding: 0;
}

/* App header styling */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Links */
.App-link {
  color: #61dafb;
}

/* Animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive adjustments */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 768px) {
  .navy button img {
    width: 24px; /* Resize icons on smaller screens */
  }

  .navy button p {
    font-size: 10px; /* Resize text on smaller screens */
  }

  .top h2 {
    font-size: 18px; /* Smaller font for mobile screens */
  }
}

.navbar-nav h2 {
  margin: 0; /* Reset margins */
  font-size: 16px; /* Default font size */
}

@media (max-width: 768px) {
  .navbar-nav h2 {
    display: none; /* Hide headings on small screens */
  }
}

.logo-container {
  display: flex;              /* Use flexbox layout */
  flex-direction: row;     /* Stack items vertically */
  align-items: center;        /* Center items horizontally */
  justify-content: center;    /* Center items vertically */
  text-align: center;         /* Center text within the container */
}

.book-now-link {
  text-decoration: none;     /* Use the current text color */
}

.navy img {
  width: 20px; /* Set the width of the icons */
  height: 20px; /* Set the height of the icons */
  /* You can also maintain aspect ratio by using height: auto; */
}

.navy p {
  font-size: 12px; /* Adjust the font size for labels */
}