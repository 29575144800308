/* .topimg {
    width: 100%;
    height: 500px;
    object-fit: cover;
} */

/* .topimg {
    animation: zoomLoop 20s ease-in-out infinite alternate;
    max-width: 100%;
}

@keyframes zoomLoop {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1); 
    }
    100% {
        transform: scale(1); 
    }
} */

.images {
    width: 500px;
    height: 300px;
    object-fit: fill;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.8);
}

.aboutUs {
    margin-bottom: 5rem;
}
.aboutUs div {
    text-align: center;
    margin: 1rem;
}
.aboutUs hr {
    width: 300px;
    color: black;
    height: 2px;
}

.aboutUs .conts{
    text-align: center;
}

.aboutUs .conts .divs {
    display: flex;
    justify-content: center;
}

.textCenter{
    text-align: center;
}

.image-container {
    position: relative;
    text-align: center;
    height: 400px;
}

.topimg {
    width: 100%; /* Adjust as needed */
    height: 100%;
    display: block;
    object-fit: cover;
}

.heading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* Or any color that contrasts with the image */
    font-size: 4em; /* Adjust size as needed */
    font-weight: bold; /* Optional: for emphasis */
    margin: 0;
    font-family: 'tribeca';
}


  