body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
  }
  
  .conts {
    max-width: 1300px;
    margin: auto 0;
    padding: 20px;
    text-align: center;
  }
  .conts section p{
    max-width: 800px;
  }
  
  header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  section {
    margin-bottom: 40px;
  }
  
  section h2 {
    color: #343a40;
  }
  
  footer {
    text-align: center;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #dee2e6;
  }
  
  footer p {
    color: #6c757d;
  }
  
  .spice{
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.7);
    border-radius: 25px;
    margin-bottom: 1rem;
  }