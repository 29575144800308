/* .popup .overlay{
    position: fixed;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
    display: none;
    overflow-y: auto; 
}

.popup .content {
    margin: auto;
   
    position: fixed;
    top: 50%;
    right: 0px;
    transform: translate(-50%, -50%) scale(0);
    background: #fff;
    width: 320px;
    height: max-content;
    z-index: 2;
    text-align: center;
    padding: 20px;
    border-radius: 20px;
    box-sizing: border-box;
} */

/* .popup .close-btn {
    position: absolute;
    left: 100%;
    bottom: 5%;
    width: 50px;
    height: 50px;
    background-color: #222;
    color: #fff;
    font-size: 60px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    border-radius: 50px;
    z-index: 9999;
} */

/* .popup .close-btn{
    position: absolute;
    right: 5%;
    bottom: 5%;
    width: 30px;
    height: 30px;
    background-color: #222;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    border-radius: 50px;
} */

/* .popup.active .overlay{
    display: block;
}

.popup.active .content{
    transform: translate(-50%, -50%) scale(1);
    transition: all 300ms ease-in-out;
} */

/* .popup .close-btn {
    position: fixed; 
    right: 0px;
    top: 20px;
    z-index: 9999; 
    width: 30px;
    height: 30px;
    background-color: #222;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    border-radius: 50px;
}

body.popup-open {
    overflow: hidden;
} */






.popup .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Dark overlay */
    z-index: 1;
    display: none; /* Hidden by default */
    overflow-y: auto;
}

.popup.active .overlay {
    display: block; /* Shows the overlay when popup is active */
}

.popup .content {
    position: fixed;
    top: 50%;
    right: 0; /* Align to the right edge */
    transform: translateY(-50%) scale(0); /* Centers vertically */
    /* background: #fff; */
    width: 320px;
    height: max-content;
    z-index: 2; /* Higher than overlay */
    text-align: center;
    padding: 20px;
    border-radius: 30px;
    box-sizing: border-box;
}

.popup.active .content {
    transform: translateY(-50%) scale(1); /* Scales up the content when active */
    transition: all 300ms ease-in-out;
}
