.navy {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 6px;
    background-color: rgb(255, 255, 255);
    padding-top: 5px;
    height: 60px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.9);
}

.top {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 6px;
}

.icons{
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.icon {
    display: flex;
    flex-direction: row;
}