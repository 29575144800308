main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  main div {
    max-width: 900px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.6;
  }
  /* .images {
    width: 70%;
    height: 300px;
    object-fit: fill;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.8);
} */
.images {
    width: 70%;
    height: 300px;
    object-fit: cover;
    object-position: top;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.8);
  }