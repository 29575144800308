/* src/Itinerary.css */
.itinerary-container {
    max-width: 1200px; /* Increased container width */
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  p {
    line-height: 1.6;
    color: #34495e;
  }
  
  /* Flexbox container for days */
  .itinerary-days {
    padding-left: 2rem;
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    gap: 30px; /* Increased space between items */
  }
  
  .day {
    flex: 100%; /* Adjusted width to accommodate increased gap */
    box-sizing: border-box;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  /* Mobile styles */
  @media (max-width: 768px) {
    .itinerary-days {
      flex-direction: column; /* Stacks items vertically on mobile */
    }
  
    .day {
      flex: 1 1 100%; /* Full width on mobile */
      margin-bottom: 30px; /* Increased space between stacked items */
    }
  }
  